

export const faTidal = {
  prefix: 'fab',
  iconName: 'tidal',
  icon: [
    512, // width
    512, // height
    [], // ligatures
    null, // unicode
    'M256 0L128 128l128 128 128-128L256 0zM128 256L0 384l128 128 128-128L128 256zm256 0L256 384l128 128 128-128L384 256z'
  ]
};