import React from 'react';
import { motion } from 'framer-motion';
import { faSpotify, faApple, faAmazon } from '@fortawesome/free-brands-svg-icons';
import { faTidal } from './customIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDeezer } from './deezerIcon';

const StreamingOptions = ({ epCover, epTitle, onBack }) => {
  const streamingServices = [
    { name: 'Spotify', icon: faSpotify, action: 'Listen', link: 'https://open.spotify.com/artist/0p5ubD6V9TwvltPixvB3FQ' },
    { name: 'Apple Music', icon: faApple, action: 'Listen', link: 'https://music.apple.com/us/artist/aren-storm/1769509360' },
    { name: 'Amazon Music', icon: faAmazon, action: 'Listen', link: 'https://music.amazon.com/artists/B0DHK6HD54/aren-storm' },
    { name: 'Tidal', icon: faTidal, action: 'Listen', link: 'https://listen.tidal.com/artist/50581788' },
    { name: 'Deezer', icon: faDeezer, action: 'Listen', link: 'https://www.deezer.com/artist/282931181' },
  ];

  return (
    <div className="min-h-screen bg-web-pattern text-gray-300 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50"></div>
      <div className="relative z-10 p-4">
        <button
          onClick={onBack}
          className="absolute top-4 left-4 bg-gray-800 text-white px-4 py-2 rounded-full font-medium hover:bg-gray-700 transition-colors duration-200 border border-gray-600"
        >
          Back
        </button>
        <div className="container mx-auto mt-16">
          <motion.img
            src={epCover}
            alt={epTitle}
            className="w-64 h-64 object-cover rounded-lg shadow-xl mx-auto mb-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          />
          <h2 className="text-4xl font-bold mb-8 text-center text-white">{epTitle}</h2>
          <div className="bg-gray-900 bg-opacity-80 rounded-lg shadow-md p-6 max-w-md mx-auto">
            {streamingServices.map((service, index) => (
              <motion.a
                key={index}
                href={service.link}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-between p-4 hover:bg-gray-800 rounded transition-colors duration-200 mb-2"
                whileHover={{ scale: 1.02 }}
              >
                <div className="flex items-center">
                  <FontAwesomeIcon icon={service.icon} className="text-2xl mr-4 text-gray-400" />
                  <span className="text-lg font-medium text-white">{service.name}</span>
                </div>
                <button className="bg-gray-700 text-white px-4 py-2 rounded-full font-medium hover:bg-gray-600 transition-colors duration-200">
                  {service.action}
                </button>
              </motion.a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StreamingOptions;