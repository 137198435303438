import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import epCover from './assets/your-ep-cover.jpg'; // Remplace par le chemin réel de ton image
import { motion } from 'framer-motion';
import StreamingOptions from './StreamingOptions';
import './customIcons';


function App() {
  const [showStreamingOptions, setShowStreamingOptions] = useState(false);

  const handleListenNowClick = () => {
    setShowStreamingOptions(true);
  };

  const handleBackClick = () => {
    setShowStreamingOptions(false);
  };

  if (showStreamingOptions) {
    return <StreamingOptions epCover={epCover} epTitle="AFTER ALL" onBack={handleBackClick} />;
  }

  return (
    <div className="min-h-screen bg-web-pattern text-gray-300 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50"></div>
      <nav className="relative z-10 p-4">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-4xl font-extrabold tracking-wider custom-text-style">
            <span className="text-white uppercase relative">
              Aren Storm
            </span>
          </h1>
          <div className="space-x-6">
            <a href="/tour" className="hover:text-white transition-colors">Tour</a>
            <a href="/merch" className="hover:text-white transition-colors">Merch</a>
          </div>
        </div>
      </nav>
      
      <main id="main-content" className="relative z-10 container mx-auto mt-16 px-4">
        <a href="#main-content" className="sr-only focus:not-sr-only">Skip to main content</a>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center"
        >
          <h2 className="text-4xl font-bold mb-8 text-white text-shadow">AFTER ALL</h2>
          <motion.div
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <img
              src={epCover}
              alt="After All EP Cover - Aren Storm"
              className="w-64 h-64 object-cover rounded-lg shadow-2xl mx-auto mb-10"
            />
          </motion.div>
          <motion.button
            onClick={handleListenNowClick}
            className="bg-gray-800 text-white px-8 py-4 rounded-full text-lg font-semibold hover:bg-gray-700 transition-colors duration-300 border border-gray-600"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            aria-label="Listen to After All EP"
          >
            Listen Now
          </motion.button>
        </motion.div>
      </main>

      <footer className="relative z-10 mt-16 p-4">
        <div className="container mx-auto flex justify-between items-center">
          <div className="flex space-x-4">
            <a href="https://www.instagram.com/aren.storm/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} className="text-2xl text-gray-400 hover:text-white transition-colors" />
            </a>
            <a href="https://www.tiktok.com/@aren.storm" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTiktok} className="text-2xl text-gray-400 hover:text-white transition-colors" />
            </a>
          </div>
          <p className="text-sm text-gray-400">&copy; 2024 Aren Storm. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
