import React from 'react';

function Tour() {
  return (
    <div className="min-h-screen bg-web-pattern flex justify-center items-center">
      <h1 className="text-4xl font-bold text-white">No upcoming tour dates</h1>
    </div>
  );
}

export default Tour;