import React from 'react';

function Merch() {
  return (
    <div className="min-h-screen bg-web-pattern flex justify-center items-center">
      <h1 className="text-4xl font-bold text-white">Available soon</h1>
    </div>
  );
}

export default Merch;
