import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Merch from './Merch'; // Import de la nouvelle page Merch
import Tour from './Tour';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import de React Router
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />  {/* Page principale */}
        <Route path="/tour" element={<Tour />} />  {/* Tour page added */}
        <Route path="/merch" element={<Merch />} />  {/* Page Merch */}
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
